<template>
    <div class="blog-content">
        <div class="c-title">
            <h1>a-puhaha</h1>
            <p>如果我贏了，你就是我的人了。</p>
        </div>

        <!-- 内容输出部分 -->
        <div class="c-wrap">
            <ArticleBigImg v-if="topArticle" :isTop="topArticle.articleTop" :ArticleInfo="topArticle" />
            <div v-for="item in articleList" :key="item.key">
                <component :is="ARTICLE_TYPE_COMPONENT_MAP[item.articleType]" :ArticleInfo="item" />
            </div>
            <!-- 大图模式 -->

            <ArticleBigImg />
            <!-- 左图模式 中图模式-->
            <ArticleMiddleImg />
            <!-- icon模式  小图模式-->
            <ArticleSmallImg />
            <!-- 分页按钮 -->
            <div class="fenye">
                <a-pagination v-model="current" :total="total" show-less-items :pageSize="pageSize" @change="changeCurrent" />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex'
    import {ARTICLE_TYPE_COMPONENT_MAP} from '@/lib/article_const'
    import ArticleBigImg from './components/ArticleBigImg.vue'
    import ArticleMiddleImg from './components/ArticleMiddleImg.vue'
    import ArticleSmallImg from './components/ArticleSmallImg.vue'
    export default {
        components: {ArticleBigImg, ArticleMiddleImg, ArticleSmallImg},
        name: 'BlogContent',
        data() {
            return {
                total: 0,
                current: 1,
                pageSize: 10,
                articleList: [],
                topArticle: {},
                ARTICLE_TYPE_COMPONENT_MAP,
            }
        },
        // comput
        methods: {
            ...mapActions({
                vxGetArticleList: 'root/getArticleList',
                vxGetTopArticle: 'article/getTopArticle',
            }),
            async getArticleList() {
                try {
                    const data = await this.vxGetArticleList({pageNum: this.current - 1, pageSize: this.pageSize})
                    console.log(data)
                    this.articleList = data.data.map((item, index) => {
                        return {
                            ...item,
                            key: item._id,
                        }
                    })
                    this.total = data.total
                } catch (error) {
                    this.$message.error('Internet Error!')
                }
            },
            async getTopArticle() {
                const {data} = await this.vxGetTopArticle()
                console.log(data)
                this.topArticle = data[0]
            },
            changeCurrent() {
                this.getArticleList()
            },
        },
        mounted() {
            this.getTopArticle()
            this.getArticleList()
        },
    }
</script>

<style lang="less" scoped>
    .blog-content {
        position: relative;
        min-height: calc(100vh - 50px);
        background-color: #f1f3f4;

        .c-title {
            box-sizing: border-box;
            height: 100px;
            padding: 20px 20px 0;
            background-color: #f9f9f9;
            h1 {
                font-weight: 200;
            }
            p {
                margin-top: 5px;
                letter-spacing: 2px;
                color: #777;
                font-size: 13px;
            }
        }
        .c-wrap {
            padding: 20px;
            .top-article {
                .layer,
                .right-container {
                    h4,
                    span,
                    p {
                        color: #fff;
                    }
                }
            }
            .article {
                margin-bottom: 20px;
                border-radius: 6px;
                overflow: hidden;
                background-color: #fff;
                cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
                h4 {
                    color: #555;
                    font-size: 22px;
                }
                p {
                    color: #777;
                    font-size: 14px;
                }
                .author-info {
                    color: #a0a0a0;
                    font-size: 13px;
                    ul {
                        display: flex;
                        line-height: 15px;
                        li {
                            padding: 0 8px;
                            display: flex;
                            justify-content: center;
                            span {
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .divider {
                    margin-bottom: 15px;
                    border-top: 1px solid rgba(237, 241, 242, 0.6);
                }
            }
            .type-full-bg {
                position: relative;

                height: 250px;

                img {
                    width: 100%;
                    height: 250px;
                }
                .layer {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 30px;
                    color: #fff;
                    font-size: 22px;
                    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5));
                    h4 {
                        margin-bottom: 10px;
                        font-weight: normal;
                    }
                    span {
                        margin: 2px 15px 0 0;
                        padding: 1px 7.8px;
                        font-size: 13px;
                        border-radius: 6px;
                        background-color: #f05050;
                    }
                    p {
                        font-size: 14px;
                    }
                }
            }
            .type-left-bg {
                display: flex;
                background-color: #fff;
                height: 190px;
                img {
                    width: 201px;
                    height: 190px;
                }
                .right-container {
                    padding: 30px 50px 15px;
                    h4 {
                        margin-bottom: 10px;
                    }
                    p {
                        line-height: 25px;
                        margin-bottom: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            .type-left-icon {
                padding: 30px 30px 10px;
                .icon-article-con {
                    // display: flex;
                }
                .meta-icon {
                    float: left;
                    width: 42px;
                    height: 42px;
                    margin-right: 15px;
                    font-size: 35px;
                }

                h4 {
                    margin-bottom: 10px;
                    text-overflow: ellipsis;
                }
                p {
                    line-height: 25px;
                    // padding-bottom: 12px;
                    margin-bottom: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: block;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                    // line-height: 2em;
                    text-indent: 4em;
                }
            }
        }

        /deep/ .fenye {
            min-width: 240px;
            max-width: 300px;
            height: 30px;
            margin: 50px auto;
            cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
            ul {
                .ant-pagination-item,
                .ant-pagination-prev,
                .ant-pagination-next {
                    margin-right: 0;
                }
                .ant-pagination-item,
                .ant-pagination-item-link {
                    border: none;
                }
                .ant-pagination-item {
                    border-radius: 0;
                    font-size: 12px;
                    height: 30px;
                }
                .ant-pagination-item-active {
                    color: #fff;
                    background-color: #111;
                    a {
                        color: #fff;
                    }
                }
                li:hover {
                    a {
                        color: #000 !important;
                    }
                    background-color: #eeeeee;
                }
            }
            .ant-pagination-prev,
            .ant-pagination-next,
            .ant-pagination-jump-prev,
            .ant-pagination-jump-next {
                min-width: 30px;
                height: 30px;
            }
        }
    }
</style>
