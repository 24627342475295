<template>
    <div class="article type-full-bg top-article" @click="$router.push({name: 'Article', query: {articleId: ArticleInfo._id}})">
        <div class="article-cover">
            <img v-if="isTop" src="@/assets/images/p1.jpg" alt="" />
            <img v-else :src="ArticleInfo.articleImgUrl" alt="" />
        </div>
        <div class="layer">
            <h4><span v-if="isTop">置顶</span> {{ ArticleInfo && ArticleInfo.articleTitle }}</h4>
            <p>{{ ArticleInfo && ArticleInfo.articleDesc }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ArticleBigImg',
        props: {
            ArticleInfo: {
                type: Object,
                default: () => {},
            },
            isTop: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {}
        },

        mounted() {},

        methods: {},
    }
</script>

<style lang="less" scoped>
    .article {
        margin-bottom: 20px;
        border-radius: 6px;
        overflow: hidden;
        background-color: #fff;
        // color: #fff;
        cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
        h4 {
            color: #fff;
            font-size: 22px;
        }
        p {
            color: #fff;
            font-size: 14px;
        }
        .author-info {
            color: #a0a0a0;
            font-size: 13px;
            ul {
                display: flex;
                line-height: 15px;
                li {
                    padding: 0 8px;
                    display: flex;
                    justify-content: center;
                    span {
                        margin-right: 10px;
                    }
                }
            }
        }
        .divider {
            margin-bottom: 15px;
            border-top: 1px solid rgba(237, 241, 242, 0.6);
        }
    }
    .type-full-bg {
        position: relative;

        height: 250px;

        .article-cover {
            width: 100%;
            height: 250px;
            img {
                width: 100%;
            }
        }
        .layer {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 30px;
            color: #fff;
            font-size: 22px;
            background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5));
            h4 {
                margin-bottom: 10px;
                font-weight: normal;
            }
            span {
                margin: 2px 15px 0 0;
                padding: 1px 7.8px;
                font-size: 13px;
                border-radius: 6px;
                background-color: #f05050;
            }
            p {
                font-size: 14px;
            }
        }
    }
</style>
