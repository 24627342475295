<template>
    <div class="article type-left-icon" @click="$router.push({name: 'Article', query: {articleId: ArticleInfo._id}})">
        <div class="icon-article-con">
            <div class="meta-icon" v-if="ArticleInfo.articleImgUrl">
                <img :src="ArticleInfo.articleImgUrl" alt="" />
            </div>
            <!-- <div class="title-and-desc"> -->
            <h4>{{ ArticleInfo && ArticleInfo.articleTitle }}</h4>
            <p>{{ ArticleInfo && ArticleInfo.articleDesc }}</p>
            <!-- </div> -->
        </div>
        <div class="divider"></div>
        <div class="author-info">
            <ul>
                <li>
                    <span class="m-r-sm right-small-icons"
                        ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-user"
                        >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle></svg></span
                    >a-puhaha
                </li>
                <li>
                    <span class="right-small-icons m-r-sm"
                        ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-clock"
                        >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline></svg></span
                    >{{ formatTime(ArticleInfo && ArticleInfo.articleDate) }}
                </li>
                <li>
                    <span class="right-small-icons m-r-sm"
                        ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-message-square"
                        >
                            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg></span
                    >{{ ArticleInfo && ArticleInfo.commentNumber }} 条评论
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {formatTime} from '@/utils/dayjs'

    export default {
        name: 'ArticleSmallImg',
        props: {
            ArticleInfo: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {}
        },

        mounted() {},

        methods: {
            formatTime(time) {
                return formatTime(time, 'YYYY 年 MM 月 DD 日')
            },
        },
    }
</script>

<style lang="less" scoped>
    .article {
        margin-bottom: 20px;
        border-radius: 6px;
        overflow: hidden;
        background-color: #fff;
        cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
        h4 {
            color: #555;
            font-size: 22px;
        }
        p {
            color: #777;
            font-size: 14px;
        }
        .author-info {
            color: #a0a0a0;
            font-size: 13px;
            ul {
                display: flex;
                line-height: 15px;
                li {
                    padding: 0 8px;
                    display: flex;
                    justify-content: center;
                    span {
                        margin-right: 10px;
                    }
                }
            }
        }
        .divider {
            margin-bottom: 15px;
            border-top: 1px solid rgba(237, 241, 242, 0.6);
        }
    }
    .type-left-icon {
        padding: 30px 30px 10px;
        .icon-article-con {
            // display: flex;
        }
        .meta-icon {
            float: left;
            width: 42px;
            height: 42px;
            margin-right: 15px;
            font-size: 35px;
            border-radius: 8px;
            overflow: hidden;
            img {
                width: 100%;
                border-radius: 8px;
            }
        }

        h4 {
            margin-bottom: 10px;
            text-overflow: ellipsis;
        }
        p {
            line-height: 25px;
            // padding-bottom: 12px;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            // line-height: 2em;
            text-indent: 4em;
        }
    }
</style>
